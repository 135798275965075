//Страница карточки рецепта

import React from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { IngredientList, Kitchenware } from '../../atoms/index.js';
import { Cals } from '../../molecules/index.js';
import { ImageBlocks, Preloader, AddEditRecipe, ModalChoiceAsk, ModalInfo } from '../../organisms/index.js';
import { useDispatch, useSelector} from 'react-redux'
import { deleteRecipe, changeLikeRecipe, getRecipeId, getRecipeOwnerData } from '../../../store/api.js' 
import {totals, OpenModal} from "../../../utils/utils.js";  
import './RecipeCard.css' 
import { Helmet } from 'react-helmet-async';

export function RecipeCard (props) {
  let { id } = useParams();  
  let navigate = useNavigate();
  const { lib, statusLib } = useSelector(state => state.lib)
  const {editData, handleClose, handleOpenEdit } = OpenModal({})
  const {ingredients, status} = useSelector(state => state.ingredients)
  const { currentUser, recipeOwner } = useSelector(state => state.currentUser)
  const {recipe, statusRecipe, statusLike } = useSelector(state => state.recipe)
  const {fetching} = useSelector(state => state.recipes) 
  const [arrDesc, setArrDesc] = React.useState([]);
  const dispatch = useDispatch(); 
  const [owner, setOwner] = React.useState(false)
  const [kitchenware, setKitchenware] = React.useState(false)
  const [author, setAuthor] = React.useState(false)
  const [advice, setAdvice] = React.useState([])
  const [preparation, setPreparation] = React.useState([])
  const [ingredientSort, setIngredientSort] = React.useState(false)
  const [imageLot, setImageLot] = React.useState([])
  const [servings, setServings] = React.useState(0) // счетчик порций, default количество человек в настройке
  const [capacity, setCapacity] = React.useState(recipe.servings); // количество порций у рецепта
  const [minPortion, setMinPortion] = React.useState(0); // миним порция в рецепте
  const [isLiked, setIsLiked] = React.useState([])
  const [info, setInfo] = React.useState(false)
  const [total, setTotal] = React.useState(false) 
  const [isOpen, setIsOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [textsucces, setTextsucces] = React.useState('');
 
  //Запрос данных при каждом обновлении страницы
  React.useEffect(()=> { 
    dispatch(getRecipeId(id))  
  },[])
 
  
  React.useEffect(()=> {  
    if(recipe.link === '' && recipe.owner !== currentUser._id) {
      dispatch(getRecipeOwnerData(recipe.owner))
    }
  },[recipe, currentUser]) 

  //Запрос данных при каждом обновлении страницы
  React.useEffect(()=> { 
    if(statusRecipe === 'resolved' && status === 'resolved' ) {
      setOwner(false)
      setKitchenware(recipe.kitchenware.filter(i=> i.boolean))
      setIsLiked(false)
      setMinPortion(recipe.minPortion)
      setAdvice(recipe.advice.split(/[0-9]+\./) )
      setPreparation(recipe.preparation.split('. '))
      setImageLot(recipe.image.length)
      setIngredientSort(ingSort())
      setTotal(totals(recipe, ingredients))
      setArrDesc(Object.entries(recipe.description).filter(value => value[1] !== ''))
      setServings(1)
      searchAuthor()
      setCapacity(recipe.servings)
      if(Object.keys(currentUser).length !== 0) {
        setIsLiked(recipe.likes.some(i => i === currentUser._id))
        setOwner(recipe.owner === currentUser._id)
        setServings(currentUser.settings.sumFamily)
      }
    }
   
  },[statusRecipe, status, currentUser, recipeOwner]) 
 
  React.useEffect(()=> {  
    if(statusRecipe === 'resolved') {
    setIsLiked(recipe.likes.some(i => i === currentUser._id)) 
    } 
  },[statusLike])

  function searchAuthor() {
    if(recipe.link !=='') {
      setAuthor(new URL(recipe.link).hostname) 
    } else if(recipe.owner === currentUser._id) {
      setAuthor(currentUser.name) 
    } else if(recipeOwner) {
      setAuthor(recipeOwner.name) 
    } else {
      setAuthor('Народный рецепт') 
    }
  }
  //Сортировка ингредиентов по весовке и мере
  function ingSort (){ 
    let news = [...recipe.ingredients]
      const weightSort = news.sort(function(a, b){
        return a.weight === b.weight ? 0 : a.weight > b.weight ? -1 : 1;
      })
      const measureSort =  weightSort.sort(function(a, b){
        return a.choisMeasure === b.choisMeasure ? 0 : a.choisMeasure === 'кг' ? -1 : a.choisMeasure === 'литр' ? -1 : 1;
      })
      return measureSort
     }

  // Добавление и удаление лайков
  function handleLikeClick () { 
    dispatch(changeLikeRecipe({id: recipe._id, isLiked: !isLiked}))
  }
  

  //Удаление рецептов
  function handleOpenModaL() {
    setIsOpen(true)
  }

   //Удаление рецептов
   function handleCloseModal() {
    setIsOpen(false)
  }
  function handlerCloseError() {
    setShowModal(false)
    navigate("/recipes");
  }
  //Удаление рецептов
  function handleDelete() {
    dispatch(deleteRecipe(id)) 
    if(statusRecipe === 'resolved') {
      setIsOpen(false)
      setShowModal(true)
      setTextsucces('Удалено')
    } 

    //dispatch(deleteRecipe(id))
  }
  
function handleCountClickMinus() {
  if (capacity - minPortion > 0) {
    setCapacity(prevCapacity => prevCapacity - minPortion);
  } else {
    setInfo(true);
  }
}

function handleCountClickPlus() {
  setCapacity(prevCapacity => prevCapacity + minPortion);
  setInfo(false);
}

  return ( 
    <main className='recipe-card'>
       {Object.keys(recipe).length !== 0 &&
      <Helmet>
          <title>{`Рецепт ${recipe.name}`}</title>
          <meta name="description" content={`Пошаговый рецепт ${recipe.name} с ингредиентами и КБЖУ порции. Добавить в меню на неделю ${recipe.name}`}/> 
        </Helmet>
      }
    {statusRecipe === 'loading' ? <Preloader />
    : Object.keys(recipe).length !== 0 &&
    <>
      <section className='recipe__column'>
        <div className='recipe__info-block'>
          <ImageBlocks onCardClick={props.onCardClick} imageLot={imageLot} name={recipe.name} image={recipe.image}/> 
          <div className='recipe__tags'>
          <ul className='recipe__list'>
           {recipe.typeOfDiet.map((i, index)=>(
             i.boolean &&
               <li key={index} className='recipe__type item__link'>{i.nameRu}</li>
             
           ))
           }
            </ul>
          <div className='recipe__author'>
            <p className='recipe__author_title font24Norm'>Источник: </p>
            <a href={`${recipe.link !=='' ? recipe.link : '#'}`} 
              target='_blank' rel="noreferrer" 
              className='recipe__author_title font24Norm'>{author}</a>
          </div>
          </div>
        </div>
        <div className='recipe__short'>
            <div className='recipe__head'>
          <div className='recipe__heading' >
            <div className='recipe__category color font20Norm recipe-card__category' >{recipe.category}</div>
            <ul className='recipe__icon' >
              {props.loggedIn &&
                <li className={`l-size ${isLiked 
                  ? 'gg-active-like' : 'gg-like'}`} 
                  onClick={handleLikeClick}>
                    
                  </li>
              }
              {(owner || currentUser.supervisor) &&
            <>
              <li className='gg-delete l-size' onClick={handleOpenModaL}></li>
              <li className='gg-edit l-size' onClick={handleOpenEdit}></li>
            </>
            }
              <li className='chess__time'>
                <span className='gg-clock l-size' />
                <span className='gg-time l-size'>{recipe.time} мин</span>
              </li>
            </ul>
          </div>
          <h1 className='recipe__title font35Bold'>{recipe.name}</h1>
          </div>
          <div className='recipe__head_info'>
          <div>
          <Cals calories={recipe.calories} total={Math.round(total/recipe.servings)} servings={recipe.servings}/>
          {kitchenware.length>0 &&
          <div className='recipe__kitchenware' >
            <p className='recipe__subtitle font24Bold'>Техника</p>
            <ul className='recipe__kitchenware_list'>
            {
              kitchenware.map((i)=> (
               <Kitchenware key={i._id} item={i} />
              ))
            }
            </ul>
          </div>
          }
           </div>
           {recipe.advice.length> 0 &&
            <div className='recipe__advice' >
              <p className='recipe__advice_subtitle font20Bold'>Советы</p>
               {
                 advice.map((i, index)=> (
                  <p key={index} className='recipe__advice_paragraph font20Norm'>{i}</p>
                 ))
               }
           </div>    
          }
          {recipe.preparation.length> 0 &&
            <div className='recipe__advice' >
              <p className='recipe__advice_subtitle font20Bold'>Подготовка продуктов</p>
              {
                 preparation.map((i, index)=> (
                   <p key={index} className='recipe__advice_paragraph font20Norm'>  {i}</p>
                 ))
               }
           </div>    
          }
        </div>
        </div>   
      </section>
      <section className='recipe__column'>
        <div className='recipe__ingredients'>
        <div className='recipe__ingredients_heading'>
          <h2 className='recipe__subtitle font24Bold'>Ингредиенты</h2>
          <div className='recipe__row' htmlFor='servings'>
          {info && <span className="add-form__item-error servings-error">Минимальное количество {minPortion} порции </span>}
            <div  className='gg-dish'> </div>
              <label className='add-recipe__counter'>
                <button type='button' onClick={handleCountClickMinus} className={`add-recipe__button add-recipe__button_type_minus ${info ? '': ''}`}></button> 
                <input disabled type='text' value={capacity} className='add-recipe__input add-recipe__input_servings' name='servings' id='servings'/>
                <button type='button' onClick={ handleCountClickPlus} className='add-recipe__button add-recipe__button_type_plus'></button> 
              </label>  
          </div>  
          </div>
          <ol className='recipe__ing-list'>
          { status=== 'resolved' && ingredientSort &&
          ingredientSort.map((item)=> (
            <IngredientList item={item} ingredients={ingredients} key={item._id} capacity={capacity} servings={recipe.servings}/>
          ))
          }  
          </ol>
        </div>
        <div className='recipe__desc'> 
          <h3 className='recipe__subtitle font24Bold'>Способ приготовления</h3>
          <ul className ='recipe__desc_list font20Norm'>
          {arrDesc &&
          arrDesc.map((item, i)=> (
           <li key={i} className='recipe__desc_paragraph font20Norm'><span className='recipe__desc_step font20Norm'>Шаг {i+1} </span> 
           <span>{item[1]}</span></li>
          ))
          }
          </ul>
        </div>
      </section>
 
    </>
   }
    {Object.keys(recipe).length !== 0  && statusLib === 'resolved' &&
      <AddEditRecipe onClose={handleClose} card={recipe && recipe} isEditOpen={editData}/>
    }
    <ModalChoiceAsk 
      isOpen={isOpen} 
      onClose={handleCloseModal} 
      onClick={handleDelete} 
      cards={recipe} 
    />
    <ModalInfo isOpen={showModal} textError={textsucces} onClose={handlerCloseError} icon={true} name="modal-info"/>
  </main>
  )
}

