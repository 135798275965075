//Список продуктов на странице рецепта

import React from 'react';
import {declensions} from '../../../constants/constants'
export function IngredientList (props) { 
  const item = props.item
  const capacity = props.capacity
  const measure = item.choisMeasure === 'г' || item.choisMeasure === 'кг' || item.choisMeasure === 'мл' || item.choisMeasure === 'шт' ||
  item.choisMeasure === 'ч.л' || item.choisMeasure === 'ст.л'
  const weight = item.choisMeasure === 'по вкусу' || item.choisMeasure === 'щепотка'
  ? '' 
  : (Math.ceil((item.weight/props.servings)*capacity*100)/100) //Расчет веса исходя из количества персон или счетчика
  const ingBase = props.ingredients.find(i=> i._id ===item._id) // находим по id нужный ингредиент*/
 
  const findDeclension = (number, word) => {
    const declension = declensions.find(item => item.ncase === word);
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return declension.gcasemulti;
    }
    n %= 10;
    if (n === 1) {
      return declension.ncase;
    }
    if (n >= 2 && n <= 4) {
      return declension.gcase;
    }
    return declension.gcasemulti; 
    }

  return (
    <li className='product-item'>
      <div className='product-item__head'>
        <p className='product-item__title font20Norm product-card__title'>{ingBase.name}</p>
        <span className='product-item__title font20Norm product-card__title'> {ingBase.trait}</span>
        <span className='recipe__border'> </span>
      </div>
      <span className='product-item__weight font20Norm'>{weight} </span>
      <span className='product-item__measure font20Norm'>{measure ?  item.choisMeasure : findDeclension(weight, item.choisMeasure)}  </span> 
    </li>
  )
}