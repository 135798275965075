// Страница о сервисе

import React from 'react'; 
import {ProductItem } from './ProductItem.js'
import { useSelector } from 'react-redux'


export function ProductCat(props) {
  const item = props.item
  const { ingredients, status } = useSelector(state => state.ingredients)
 //console.log(props.checkFoodList)
  //Фильтрация продуктов по категориям
  const arrFilter =  props.checkFoodList.filter(i=> {
    let product = ingredients.find(r=> r._id === i.id) 
   if (product !== undefined) {
    return product.category === item.nameRu
   } 
  })
 
  return (
    <div className='product-category'>
    {arrFilter.length > 0 &&
      <>
        <p className='product-category__name font16Bold'>{item.nameRu}</p>
        <div className='product-list'>
        {arrFilter.map((item)=> (
          <ProductItem
          item={item} 
          key={`${item.id}_${item.choisMeasure}`}
          handleChoice={props.handleChoice}
          subDish={props.subDish}
          servings={props.servings}
        />
        ))
       }
        </div>
        </>
      }
       </div>
  )  
}
