import { createAsyncThunk } from "@reduxjs/toolkit";
  //   export const BASE_URL = 'http://localhost:3002'
  export const BASE_URL = 'https://api.menubook.ru'; 
                                           /* ИНГРЕДИЕНТЫ */
  // Получение списка ингредиентов
  export const getIngredients = createAsyncThunk(
    'ingredients/getIngredients',
    async (_, {rejectWhithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/ingredients`, {
              method: 'GET',
              headers: {
               'Content-Type': 'application/json',
              },
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )

  //Добавление ингредиентов
  export const postIngredients = createAsyncThunk(
    'ingredients/postIngredients',
    async (arr, {rejectWhithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/ingredients`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(arr)
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )

  //удаление ингредиентов
  export const deleteIngredients = createAsyncThunk(
    'ingredients/deleteIngredients',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/ingredients/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })
 
  //Изменение ингредиентов
  export const editIngredients = createAsyncThunk(
    'ingredients/editIngredients',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/ingredients/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            name: arr.values.name,
            nameRu: arr.values.name,
            trait : arr.values.trait,
            nameEn: arr.values.nameEn,
            calories: {
              protein: arr.values.protein,
              fats: arr.values.fats,
              carb: arr.values.carb,
              calories: arr.values.calories,
              digest: arr.values.digest,
            },
            measure: arr.measure,
            accusativeCase: '',
            genitiveCase: '',
            instrumentalCase: '',
            category: arr.values.category,
            desc: `${arr.values.desc ? arr.values.desc: ''}`,
            image: [],
            choisMeasure: 'г',
            weight: 0,
          })
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

                                             /* РЕЦЕПТЫ */

  //Добавление рецепта в базу
  export const postRecipe = createAsyncThunk(
    'recipe/addrecipe',
    async (arr, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/recipe`, {
          method: 'POST',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(arr)
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Получение рецептов из базы c настройками
  export const getRecipe = createAsyncThunk(
    'recipe/getrecipe',
    async (arr, {rejectWhithValue}) => {
      try { 
          const response = await fetch(`${BASE_URL}/suite?${arr.urlString}${arr.param}`, {
            method: 'GET',
            headers: {
             'Content-Type': 'application/json',
            },
            credentials: 'include',
          })
          if(!response.ok) {
            throw new Error('Server Error!')
          }
          const data = await response.json();
          return data;
        } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

   //Получение рецептов c лайками пользователя
   export const getRecipeLike = createAsyncThunk(
    'recipe/getrecipelike',
    async (id, {rejectWhithValue}) => {
      try { 
          const response = await fetch(`${BASE_URL}/recipe/like/${id}`, {
            method: 'GET',
            headers: {
             'Content-Type': 'application/json',
            },
            credentials: 'include',
          })
          if(!response.ok) {
            throw new Error('Server Error!')
          }
          const data = await response.json();
          return data;
        } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Удаление рецепта из базы
  export const deleteRecipe = createAsyncThunk(
    'recipe/deleteRecipe',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
      return rejectWhithValue(error.message)
    }
  })
  
  //Изменение лайков на рецепте
  export const changeLikeRecipe = createAsyncThunk(
    'recipe/changeLike',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${arr.id}/likes`, {
          method: arr.isLiked ? 'PUT' : 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWhithValue(error.message)
      }
  })
  
   //Получение рецепта по id
   export const getRecipeId = createAsyncThunk(
    'recipe/getrecipeId',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${id}`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

   //Получение рецептов пользователя
   export const getRecipeOwner = createAsyncThunk(
    'recipe/getrecipeOwner',
    async (id, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/recipe/owner/${id}`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        const count = response.headers.get('Total-Card')
        return ({data, count});
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Изменение рецепта
  export const editRecipe = createAsyncThunk(
    'recipe/editRecipe',
    async (arr, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/recipe/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            name: arr.values.title,
            nameRu: arr.values.title,
            description: arr.desc,
            category: arr.values.category,
            image: arr.imageBlob,
            ingredients: arr.ingredientsData,
            servings: arr.capacity,
            feast: arr.values.feast,
            time: `${(parseInt(arr.values.hours === undefined || arr.values.hours === '') ? 0 : arr.values.hours )*60+parseInt((arr.values.minute ===undefined || arr.values.minute === '') ? 0 : arr.values.minute)}`,
            typeOfDiet: arr.types,
            calories: arr.result,
            minPortion: arr.values.minPortion,
            link: arr.values.author === undefined ? '' : arr.values.author,
            heat: arr.values.heat,
            thermo: arr.values.thermo,
            fresh: arr.values.fresh,
            kitchenware: arr.kitchenware,
            preparation: arr.values.preparation,
            metaDesc: arr.values.metaDesc,
            activeCooking: arr.values.activeCooking,
            advice: arr.values.advice,
            desc:[],
          })
        })
        if(!response.ok) { 
          throw new Error(response.status)
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (!error.response) {
          throw error
        } 
        return rejectWhithValue(error.response.data)
      } 
    }
  )

  export const fetchFilter = createAsyncThunk(
    'recipe/suite',
    async (arr, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/recipe/search?page=${arr.currentPage}&limit=${arr.limit}${arr.params}&like=${arr.like}&owner=${arr.owner}&feast=${arr.feast}&title=${arr.searchWords}${arr.ingredient}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }, 
        });
        if(!response.ok) {
        throw new Error('Server Error!')
        }
        const data = await response.json();
        const count = response.headers.get('Total-Card')
        return ({data, count});
      } catch (error) {
        return rejectWhithValue(error.message) 
      } finally {
       // setFetching(false)
      } 
  })
  
                                             /* ПЛАН МЕНЮ */
  //Получение списка меню из базы
  export const getMenu = createAsyncThunk(
    'menu/getmenu',
    async (_, {rejectWhithValue}) => {
     
      try {
        const response = await fetch(`${BASE_URL}/plan`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //получение плана питания пользователя
  export const getPlan = createAsyncThunk(
    'menu/getplan',
    async (arr, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/plan/${arr}`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Добавление плана питания
  export const postPlan = createAsyncThunk(
    'menu/postplan',
    async (plan, {rejectWhithValue}) => { 
      try { 
            const response = await fetch(`${BASE_URL}/plan`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({ 
                /*date: plan.date,
                breakfast: plan.breakfast,
                dinner: plan.dinner,
                snack: plan.snack,
                foodList: plan.foodList,
                lateDinner: plan.lateDinner,
                supper: plan.supper,
                lunch : plan.lunch,
                title: 'Menu1',*/
                plan: plan
              })
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )

  // Обновление плана питания
  export const updatePlan = createAsyncThunk(
    'menu/updateplan',
    async (plan, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/plan`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ 
            plan: plan
            /*
            date: arr.plan.date,
            breakfast: arr.plan.breakfast,
            dinner: arr.plan.dinner,
            snack: arr.plan.snack,
            foodList: arr.plan.foodList,
            lateDinner: arr.plan.lateDinner,
            supper: arr.plan.supper,
            lunch: arr.plan.lunch,
            title: 'Menu1',*/
           })
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  // Обновление плана питания с пересчетов списка продуктов на сервере
  export const updatePlanFood = createAsyncThunk(
    'menu/updateplanfood',
    async (arr, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/plan/food/${arr.id}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ 
            date: arr.plan.date,
            breakfast: arr.plan.breakfast,
            dinner: arr.plan.dinner,
            snack: arr.plan.snack,
            foodList: arr.plan.foodList,
            lateDinner: arr.plan.lateDinner,
            supper: arr.plan.supper,
            lunch: arr.plan.lunch,
            title: 'Menu1',
           })
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Удаление плана питания из базы
  export const deletePlan = createAsyncThunk(
    'menu/deleteplan',
    async (id, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/plan/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  /*//получение плана питания пользователя
  export const getFoodList = createAsyncThunk(
    'foodlist/getfoodlist',
    async (id, {rejectWhithValue}) => { 
      try { 
        const response = await fetch(`${BASE_URL}/foodlist/${id}`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json(); 
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })*/
  
  //получение плана питания пользователя на выбранные даты
  export const getFoodList = createAsyncThunk(
    'foodlist/getfoodlist',
    async (arr, {rejectWhithValue}) => { 
      try { 
        const response = await fetch(`${BASE_URL}/foodlist/food/${arr.id}`, {
          method: 'POST',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ 
            date: arr.date,
          })
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json(); 
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Добавление списка продуктов
  export const postFoodList = createAsyncThunk(
      'foodlist/postfoodlist',
      async (arr, {rejectWhithValue}) => { 
        try { 
              const response = await fetch(`${BASE_URL}/foodlist`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ 
                  date: arr.date,
                  productList: arr.editFoodlist, 
                })
              })
              if(!response.ok) {
                throw new Error('Server Error!')
              }
              const data = await response.json();
              return data;
            } catch (error) {
                return rejectWhithValue(error.message)
            }
      }
  )
  
  // Обновление списка продуктов
  export const updateFoodList = createAsyncThunk(
    'foodlist/updatefoodlist',
    async (arr, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/foodlist/${arr.cardId}`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ 
            productList: arr.editFoodlist,
          })
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

  //Удаление списка продуктов
  export const deleteFoodList = createAsyncThunk(
    'foodlist/deletefoodlist',
    async (id, {rejectWhithValue}) => { 
      try {
        const response = await fetch(`${BASE_URL}/foodlist/${id}`, {
          method: 'DELETE',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

   //Получение данных для отображения в меню
   export const getRecipeData = createAsyncThunk(
    'menu/getRecipeData',
    async (arr, {rejectWhithValue}) => { 
      try { 
            const response = await fetch(`${BASE_URL}/recipe/suite`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({
                id: arr,
              })
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    })

   
  export const fetchSuite = createAsyncThunk(
    'menu/fetchsuite',
    async (id, {rejectWhithValue}) => {  
      try {  
      const response = await fetch(`${BASE_URL}/recipe/project/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if(!response.ok) {
        throw new Error('Server Error!')
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWhithValue(error.message) 
    } finally {
    
    }
  } )

    
                                             /* ПОЛЬЗОВАТЕЛИ */
 
  //Получение данных о пользователе
  export const getUser = createAsyncThunk(
    'user/getUser',
    async (_, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/users/me`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) { 
          throw new Error(response.status)
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (!error.response) {
          throw error
        } 
        return rejectWhithValue(error.response.data)
      } 
  })

  //Получение данных о пользователе
  export const getUserActivity = createAsyncThunk(
    'user/getUserActivity',
    async (_, {rejectWhithValue}) => {
      try {
        const response = await fetch(`${BASE_URL}/users/userActivity`, {
          method: 'GET',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        if(!response.ok) { 
          throw new Error(response.status)
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (!error.response) {
          throw error
        } 
        return rejectWhithValue(error.response.data)
      } 
  })

   // Обновление настроек пользователя
   export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (settings, {rejectWhithValue}) => {  
      try {
        const response = await fetch(`${BASE_URL}/users/me/settings`, {
          method: 'PATCH',
          headers: {
           'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({settings: settings})
        })
        if(!response.ok) {
          throw new Error('Server Error!')
        }
        const data = await response.json();
        return data;
      } catch (error) {
          return rejectWhithValue(error.message)
      }
  })

    //получение данных создателя рецепта(для отображения в источнике)
    export const getRecipeOwnerData = createAsyncThunk(
      'user/getrecipeowner',
      async (id, {rejectWhithValue}) => {  
        try {
          const response = await fetch(`${BASE_URL}/user/${id}`, {
            method: 'GET',
            headers: {
             'Content-Type': 'application/json',
            },
            credentials: 'include',
          })
          if(!response.ok) { 
            throw new Error(response.status)
          }
          const data = await response.json();
          return data;
        } catch (error) {
          if (!error.response) {
            throw error
          } 
          return rejectWhithValue(error.response.data)
        }
    })


                                             /* БИБЛИОТЕКА ДАННЫХ */
  // Получение данных из библиотеки
  export const getLibrary = createAsyncThunk(
    'lib/getLibrary',
    async (_, {rejectWhithValue}) => {
      try { 
            const response = await fetch(`${BASE_URL}/lib`, {
              method: 'GET',
              headers: {
               'Content-Type': 'application/json',
              },
            })
            if(!response.ok) {
              throw new Error('Server Error!')
            }
            const data = await response.json();
            return data;
          } catch (error) {
              return rejectWhithValue(error.message)
          }
    }
  )