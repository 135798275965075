import React from 'react';

const ImageFields = (props) => {   
  function handleClick() {
    props.onClick(props.image)
  }

  return (
    <>
    <img src={props.image} className="image-upload__item" alt={props.image} />
    <p className="image-upload__delete" onClick={handleClick}></p>
    </>
  )
};

export function AddImages (props) {
  
  function handleDeleteHeritageImage(image) {
    props.handleDeleteHeritageImage(image)
  } 

  return (
    <div className="image-upload">
      <label htmlFor="add-input" className="image-upload__label">
        <input 
        type="file"
        id="add-input"
        name="addImage"
        multiple="multiple"
        onChange={props.onClick}
        className="image-upload__input"
        />
        <span className="image-upload__span">Добавьте фотографии</span>
        {props.imageError && <span className="email-error add-form__item-error">{props.imageError}</span>}
      </label>
      <div className="image-upload__visual">
        {props.imageBlob.length >0 &&
        <>
        {props.imageBlob.map((item)=>(
          <ImageFields key={item}  onClick={handleDeleteHeritageImage} image={item} />
        ))}
        </>
        }
      </div>
    </div>
   
  )
}


export function EditImages (props) {
  
   function handleDeleteHeritageImage(image) {
     props.handleDeleteHeritageImage(image)
   }
   return (
     <div className="image-upload">
       <label htmlFor="edit-input" className="image-upload__label">
         <input 
         type="file"
         id="edit-input"
         name="editImage"
         multiple="multiple"
         onChange={props.onClick}
         className="image-upload__input"
         />
         <span className="image-upload__span">Добавьте фотографии</span>
       </label>
       <div className="image-upload__visual">
         {props.imageBlob.length >0 &&
         <>
         {props.imageBlob.map((item)=>(
           <ImageFields key={item}  onClick={handleDeleteHeritageImage} image={item} />
         ))}
         </>
         }
       </div>
     </div>
    
   )
 }