//Страница Планирования Праздничного меню

import React from 'react';
import { FormHoliday, AddRecipeInMenu } from '../../molecules/index.js'
import { getRecipeData} from '../../../store/api.js' 
import { MenuFeast } from '../index.js'
import {CurrentUserContext} from '../../../contexts/CurrentUserContext.js';
import {Counter, OpenModal} from '../../../utils/utils.js';
import {useFormValidation} from '../../../utils/Validator.js'
import { ProductCat} from '../../atoms/index.js' 
import {useSelector, useDispatch} from 'react-redux' 
import { Helmet } from 'react-helmet-async';
import api from "../../../utils/api.js";
import {DishChecking} from '../MenuCreate/Checking.js'
import imgfeast from '../../../images/feastimg.png'

export function HolidayPlan(props) {
  const myRef = React.useRef()
  const typeRef = React.useRef()
  const eventRef = React.useRef()
  const dispatch = useDispatch();
  const { lib, statusLib } = useSelector(state => state.lib)
  const {recipeDataMenu} = useSelector(state => state.recipes) 
  const { ingredients, status } = useSelector(state => state.ingredients)
  const [ recipes, setRecipes] = React.useState([]); 
  const [types, setTypes] = React.useState([])
  const [category, setCategory] = React.useState([]) 
  const [feastPlan, setFeastPlan] = React.useState({}) 
  const [product, setProduct] = React.useState([]) 
  const [loader, setLoader] = React.useState(false) 
  const [title, setTitle] = React.useState('Праздник') 
  let plan = JSON.parse(localStorage.getItem('plan'));
  let food = JSON.parse(localStorage.getItem('food'));
  const [subDish, setSubDish] = React.useState([]) // Список блюд где исп-ся продукты 
  const [counts, setCounts] = React.useState([]);  
  const { handleClose, setIsOpen, isOpen, hoax, handleHoax } = OpenModal({})
  const [positionMenuItem, setPositionMenuItem] = React.useState({});
  let events = ['Новый год', "День рождения", "Юбилей", "Гости"]
  const [showEventList, setshowEventList] = React.useState(false) // стейт показа выпадающего меню формы праздники
  const [showTypeList, setshowTypeList] = React.useState(false) // стейт показа выпадающего меню формы типы питания
  const [showDishList, setshowDishList] = React.useState(false) // стейт показа выпадающего меню формы блюда и их колличество
  const [capacity, setCapacity] = React.useState(1) // колличество гостей
  const submitDisabled = types.every(i=> i.boolean === false) || counts.every(i=> i.count === 0)
  const [click, setClick] = React.useState(false) 

  React.useEffect(() => { 
    if(plan !== null && Object.keys(plan).length !== 0) {
      setFeastPlan(plan)  
      setProduct(plan.foodList)
    }  
  },[])

  React.useEffect(() => {
    if(Object.keys(feastPlan).length !== 0) {
      searchPlanData() 
      setProduct(productCount(feastPlan))
    }  
  },[feastPlan]) 

  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (showTypeList && typeRef.current && !typeRef.current.contains(e.target) && !e.target.classList.contains('typetoggle') ) {
        setshowTypeList(false) 
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [showTypeList])

  React.useEffect(() => {
    const click = e => {
      if (showDishList && myRef.current && !myRef.current.contains(e.target) && !e.target.classList.contains('dishtoggle') ) {
        setshowDishList(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [showDishList])

  React.useEffect(() => {
    const click = e => {
      if (showEventList && eventRef.current && !eventRef.current.contains(e.target) && !e.target.classList.contains('eventtoggle') ) {
        setshowEventList(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [showEventList])


  React.useEffect(() => {
    if(statusLib === 'resolved' && status === 'resolved') {
      const newcat = [...lib.dish] 
      if(plan !== null && Object.keys(plan).length !== 0) { 
        const filteredArray = newcat.filter(item => item.nameEn !== 'soup' && item.nameEn !== 'bite' && item.nameEn !== 'breakfast')
        setCategory(filteredArray) 
        setCounts (plan.counts)
        setCapacity(plan.servings)
        setTypes(plan.types) 
        setTitle(plan.title)
        setSubDish(DishChecking([plan], recipeDataMenu, ingredients))
      } else {
        const newtype = [...lib.typeFoods] 
        const filteredArray = newcat.filter(item => item.nameEn !== 'soup' && item.nameEn !== 'bite' && item.nameEn !== 'breakfast')
        const filteredtype = newtype.filter(item => item.nameEn !== 'properNutrition' && item.nameEn !== 'rawFood' && item.nameEn !== 'paleoDiet'  && item.nameEn !== 'kidsmenu' && item.nameEn !== 'Table1' && item.nameEn !== 'Table5' && item.nameEn !== 'Table2')
        const newCount = filteredArray.map(item=> ({...item, count: 0}))
        setCategory(filteredArray) 
        setTypes(filteredtype)
        setCounts (newCount)
      }  
    }
   
  }, [statusLib, ingredients]);
 
  function searchPlanData() {  
    const arrMenu =  [...feastPlan.dinners, ...feastPlan.salads, ...feastPlan.dеserts, ...feastPlan.snacks,  ...feastPlan.drinks];
    const mergedArray = Array.from(new Set(arrMenu.flat()));
    if (recipeDataMenu.length === 0) { 
      // Получение фотографий и данных для отображения рецептов в плане
      dispatch(getRecipeData(mergedArray))
    } else { 
      const news = mergedArray.filter(m=> !recipeDataMenu.find(r=> r._id === m) )
      if(news.length > 0) {
        dispatch(getRecipeData(news))
      }
    }  
  }


  React.useEffect(() => { 
    if(status === 'resolved') {   
      setSubDish(DishChecking([feastPlan], recipeDataMenu, ingredients))
      localStorage.setItem(
        "plan",
        JSON.stringify(feastPlan)
      );
    }
    
  },[feastPlan])

  React.useEffect(() => {  
    if( Object.keys(feastPlan).length !== 0) {
    setFeastPlan(plan=> ({
      ...plan,
      servings: capacity,
    })) 
    } 
  },[capacity])

  function productCount(upPlane) {  
    const product = {};
     Object.values(upPlane).map((d) => {
      if (Array.isArray(d) && d.length > 0) {
        const fin = d.filter((i) => i !== undefined && !i.weight && !i.nameEn);
        for (const item of fin) { 
          const card = recipeDataMenu.find(i=> i._id === item); 
          if (card !== undefined) {
           // const ing = ingredients.find({ _id: { $in: card.ingredients.map(c => c._id) } });
           const ing = ingredients.filter(ingredient => card.ingredients.map(c => c._id).includes(ingredient._id))
              card.ingredients.forEach((ingredient) => {
                const ingBase = ing.find(c=> String(c._id) === ingredient._id)
                let key
                let weight
                let choisMeasure
                const crow = ingBase.measure.find(function(j) {
                  return j.nameRu.includes(ingredient.choisMeasure);
                });
                if(crow !== undefined) {
                  key = `${ingredient._id}_${ingBase.mainMeasure}`;
                  if(ingBase.mainMeasure === ingredient.choisMeasure) {
                    weight = Number(ingredient.weight) / card.servings
                  } else {
                    weight = (crow.ratio * Number(ingredient.weight)) / card.servings
                  }
                  choisMeasure = ingBase.mainMeasure
                } else {
                  key = `${ingredient._id}_${ingredient.choisMeasure}`;
                  weight = Number(ingredient.weight) / card.servings
                  choisMeasure = ingredient.choisMeasure
                }
                if (product[key]) { 
                  product[key].weight += Number(weight*capacity);
                } else { 
                  product[key] = {
                    id: ingredient._id,
                    weight: Number(weight*capacity),
                    choisMeasure: choisMeasure,
                    choice: false
                  };
                }
              });
            }
          };
        }
      })
      const combinedIngredients = Object.values(product); 
      return combinedIngredients 
  }
 
  const handleChangeCount = (target, name) => {
    const updatedCounts = counts.map((item) => {
      if (item.nameRu === name) {
        if (target.classList.contains('add_plus_dish') && item.count < 10) {
          return { ...item, count: item.count + 1 };
        } else if (target.classList.contains('add_minus_dish') && item.count > 0) {
          return { ...item, count: item.count - 1 };
        }
      }
      return item;
    });
  
    setCounts(updatedCounts);
  }; 

  //Добавление типов питания в массив
  const handleChangeType = (item) => { 
    const updatedTypes = types.map((type) => {
      if (type.nameEn === item.nameEn) {
       return { ...type, boolean: item.boolean };
      } else {
        return type;
      }
    });
    setTypes(updatedTypes); 
  }; 

  //Отправка данных для составления меню на сервер
  function handleSubmit(e) {
    e.preventDefault(); 
    setLoader(true)
    api.getServerFeastMenu(title, counts, capacity, types)
    .then(card=> {  
        setFeastPlan(card)
        setLoader(false)
    }) 
  }

  // Автозамена меню
  function handleAutoReplaceMenu() {
    setLoader(true)
    api.getServerFeastMenu(title, counts, capacity, types)
    .then(card=> {   
      if(Object.keys(card).length !== 0) {
        setFeastPlan(card)
        setLoader(false)
        setClick(false)
      }
    }) 
  }

  //Добавление вида блюда в меню
  function handleAddMeals(meals, item) {  
    api.getFeastDish(meals.mealsRu, types)
    .then(card=> { 
      if(card.length > 0) {
        const upPlane = Object.assign({}, item, {[meals.mealsEn]: [card[0]._id]}); 
        setFeastPlan(upPlane)
      } else {
        console.log('блюд соответствующих запросу не найдено')
      }  
     })
  }

  function handleAddMenuItem( item) {
    setIsOpen(true)
    setPositionMenuItem({
      powerMode: item.mealsEn,
      powerModeRu: item.mealsRu,
      day: feastPlan.date,
      item: item,
      replace: false
    })
  }

   //функция добавления рецепта в план
  function handleAddDish(recipe, powerMode) {  
    const upPlane = Object.assign({}, feastPlan, {[powerMode]: [...feastPlan[powerMode], recipe._id]}); 
    setFeastPlan(upPlane)
  }

  // Передает данные в модалку для замены рецепта
  function handleEdite(id, mealsEn, mealsRu, item) {
    setIsOpen(true)
    setClick(true)
    setPositionMenuItem({
      id,
      powerMode: mealsEn,
      powerModeRu: mealsRu,
      item: item,
      replace: true
    })
  }

  //функция замены рецепта в плане
  function handleReplace(id, recipe, powerMode, item) { 
    const updatePlane = Object.assign({}, item, {[powerMode]: item[powerMode].map(k=> k === id ? recipe._id : k )}); 
    setFeastPlan(updatePlane) 
    setClick(false)
  }

  // Автоматическая замена блюда в плане
  function handleAutoReplace(recipe, mealsEn, mealsRu, item) {  
    setClick(true)
    api.getFeastDish(mealsRu, types, item[mealsEn])
    .then((newDish)=> {   
      if(newDish.length > 0 ) {   
        const upPlane = Object.assign({}, item, {[mealsEn]: item[mealsEn].map(k=> k === recipe._id ? newDish[0]._id : k )});
        setFeastPlan(upPlane) 
        setClick(false)
      } else {
        setClick(false)
        props.recipeNull()
      } 
    }) 
  }

 //функция удаления рецепта из плана
  function handleDeleteRecipe(id, powerMode, item) {
    const updatePlane = Object.assign({}, item, {[powerMode]: item[powerMode].filter(k=> k !== id)});  
    setFeastPlan(updatePlane) 
  } 

  
  function handleChoiceProduct(item) { 
    setProduct(product=> product.map((element) => element.id === item.id ?item : element  ))
 
  }


 
  /////////////   обработки событий фильтров.............................................................................


  //показ=скрытие списка праздников в форме................................................
  const showCloseEvents = () => {  
    setshowEventList(!showEventList)
  }

  //показ=скрытие списка типов питания в форме................................................
  function  showType () { 
    setshowTypeList(!showTypeList)
  }



//показ=скрытие списка блюд и их колличества в форме................................................
const showDish = () => {
  setshowDishList(!showDishList)
}

////////////////////////добавление удаление гостей счетчик//////////////////////

const minusGuest = () => { 
  if(capacity < 2) {
    setCapacity(1)
  } else { 
    setCapacity(capacity - 1)
  }
}

const addGuest = () => { 
  if(capacity > 98) {
    setCapacity(99)
  } else {
     setCapacity(capacity + 1)
  } 
}
 
function handleChangeEvents(el) {
  setTitle(el)
  setshowEventList(false)
}

function handleCloseModal() {
  handleClose()
  setClick(false)
}

  return (
    <div className='feast-menu font16Norm'>
    
        <Helmet>
          <title>Составить праздничное меню, список продуктов на всех гостей</title>
          <meta name="description" content='Составить праздничное меню на Новый год и День рождения, юбилей или вечеринку'/> 
        </Helmet>
        <div className="feast-promo">
          <div className="whatCook-info">
            <h1>Что приготовить на Hовый год</h1>
            <p>Ожидая гостей на праздники, Новый год или день рождения, хозяйке необходимо тщательно подготовиться. Важно не только удивить, но и порадовать гостей вкусными и разнообразными блюдами. Подготовка к празднику всегда требует времени, которого всегда не хватает в современном мире. Необходимо придумать, что приготовить, составить список покупок и не забыть ничего важного. Мы предлагаем вам простой конструктор меню, который поможет Вам не только выбрать блюда на праздник, но и составить список покупок для нужного числа гостей.»</p>
          </div>
          <img  className='whatCook-img' src={imgfeast} alt='праздничное блюдо'/>
        </div>
        <form className="createMenu" onSubmit={props.onSubmit} >
          <h2>Составить праздничное меню</h2>
          <div className='createMenu-form'>
          {/* инпут какой праздник */}
            <fieldset className="form-event" >
              <div className='event_wrapper'>
                <p >{title}</p>
                <p className='gg-down eventtoggle' onClick={showCloseEvents}></p>
              </div>
              <Popup isOpen={showEventList} ref={eventRef} >
                {events.map((el, index) => <p className='event-text' key={index} onClick={()=> handleChangeEvents(el)}>{el}</p>)} 
              </Popup> 
            </fieldset>
            {/* инпут колличество гостей */}
            <fieldset className="form-guests" >
              <p className='feast-form__title font16Norm'>Кол-во гостей:</p>
              <div className="count_guest">
                <button  type='button' className='gg-minus m-size' onClick={minusGuest}/>
                <p className='count_guest_number font16Norm'>{capacity}</p>
                <button  type='button' className='gg-plus m-size' onClick={addGuest}/>
              </div>
            </fieldset>
            {/* инпут тип питания */} 
            <fieldset className="form-types" >
              <div className='event_wrapper'>
                <p>Тип питания</p>
                <p className='gg-down typetoggle' onClick={showType}></p>
              </div>
              <Popup isOpen={showTypeList} ref={typeRef} >
                {types.map((type,index) => (
                <div key={index} className="type_list_item"> 
                  <input name={type.nameEn} id={type.nameEn} checked={type.boolean} onChange={()=>handleChangeType({boolean: !type.boolean, nameEn: type.nameEn})} value={type.nameEn} type="checkbox" />
                  <label htmlFor={type.nameEn}>{type.nameRu} </label>
                </div>
                ))}
              </Popup> 
            </fieldset> 
            {/* инпут блюда и их кол-во */}
            <fieldset className="form-dish">
              <div className='event_wrapper'>
                <p>Блюда и их кол-во</p>
                <p className='gg-down dishtoggle' onClick={showDish}></p>
              </div>
              <Popup isOpen={showDishList} ref={myRef} >
                {category.map((dish,index) => (
                  <CountDish 
                    item={dish}
                    key={index}
                    value={counts}
                    name={dish.nameRu}
                    handleChange={handleChangeCount}
                    title={dish.nameRu}
                  />
                ))}
              </Popup> 
            </fieldset>
            {/* инпут range */}
          
          </div>
          <div className="cr_ch-menu">
          <button disabled={submitDisabled} type='submit' className={`create_change_menu ${submitDisabled ? ('feast__disabled') : ''}`} onClick={handleSubmit}>Составить меню</button>
        </div>
        </form>
       
        {Object.keys(feastPlan).length !== 0 &&
        <MenuFeast  
          feastPlan={feastPlan}
          product={product}
          recipes={recipes}
          title = {title} 
          handleAutoReplaceMenu={handleAutoReplaceMenu} 
          handleAddMeals={handleAddMeals}
          handleAddMenuItem={handleAddMenuItem}
          handleEdite={handleEdite}
          handleAutoReplace={handleAutoReplace}
          handleDeleteRecipe={handleDeleteRecipe} 
          subDish={subDish}
          handleChoice={handleChoiceProduct}
          submitDisabled={submitDisabled}
          loader={loader}
          click={click}
        />
        } 
        <AddRecipeInMenu 
          addRecipe={handleAddDish} 
          replaceRecipe={handleReplace} 
          replace={isOpen} 
          onClose={handleCloseModal} 
          closeModal={handleClose} 
          positionMenuItem={positionMenuItem} 
          isOpen={isOpen}
          feast= {true}
        />
      </div>
  
  )   
}

function CountDish(props) {
  const value =  props.value.filter (i=> i.nameRu === props.name )
  const handleChange = (e) => {  
    props.handleChange(e.target, props.name)
  } 
  return ( 
    <div className="dish_list_item">
      <p>{props.name === 'Десерты и Выпечка' ? 'Десерты' : props.name} </p>
      <div className='count_guest'>
      <button type='button' className='add_guest gg-minus add_minus_dish'  onClick={(e)=>handleChange(e)}/>
        <p>{value[0].count}</p>
      <button type='button' className='add_guest gg-plus add_plus_dish'  onClick={(e)=>handleChange(e)}/>
     </div>
      </div>

  )  
}

const Popup = React.forwardRef((props, ref) => (
  <div ref={ref} className={`feast__popup ${props.isOpen ? 'popup__open' : ''}`} >
    {props.children} 
  </div>  
));



