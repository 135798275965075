

// Разметка меню для ручного редактирования
import React, { memo } from 'react'; 
//import { MenuMeals } from '../../molecules/index.js'
import { ProductCat, AddMeals } from '../../atoms/index.js' 
import { Loader } from '../index.js' 
import { Link } from 'react-router-dom' 
import { useSelector} from 'react-redux'  
import './MenuFeast.css';  
import kcal from '../../../images/kcal.svg'
import time from '../../../images/times.svg'
import cost from '../../../images/cost.svg'
import minCost from '../../../images/cost.svg'
import { totals } from '../../../utils/utils.js';

export const MenuFeast = memo((props) => { 
  const item = props.feastPlan
  const loader= props.loader
  const {ingredients, status} = useSelector(state => state.ingredients)
  const { lib, statusLib } = useSelector(state => state.lib) 
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
  const [isOpen, setIsOpen] = React.useState(false);
  const myRef = React.useRef()
  const { dinners, dеserts, salads, snacks, drinks } = item
  const today = new Date();   
  const blockedSaveMenu = false 

  const newarr = [
    {
      arr: dinners,
      mealsRu: 'Основное блюдо',
      mealsEn: 'dinners',
    }, 
    {
      arr: salads,
      mealsRu: 'Салаты',
      mealsEn: 'salads',
    }, 
    {
      arr: snacks,
      mealsRu: 'Закуски',
      mealsEn: 'snacks',
    },  
    {
      arr: dеserts,
      mealsRu: 'Десерты и Выпечка',
      mealsEn: 'dеserts',
    }, 
    {
      arr: drinks,
      mealsRu: 'Напитки',
      mealsEn: 'drinks',
    }, 
  ] 
  const zeroMeals = newarr.filter(i=> i.arr!== undefined && i.arr.length === 0) //Отсутствующие приемы пищи за день
    let [cal, setCal] = React.useState(0)
    let [price, setPrice] = React.useState(0) 
 
  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (isOpen && myRef.current && !myRef.current.contains(e.target) && !e.target.classList.contains('gg-add')) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [isOpen]) 
  
  //Запуск перерасчета цены и калорийности при изменении меню
  React.useEffect(() => {  
    if(status === 'resolved') { 
      let {cal, price} = countSumCalories()
      setCal(cal)
      setPrice(price)
    }
  }, [item, statusMenu]) 
 
  function countSumCalories() {
      let cal= 0
      let price= 0
      const dex = props.product 
        let result = dex.reduce(function(acc,v, index){
          const ingredient = ingredients.find(f => f._id === v.id); 
            if(ingredient  !== undefined) {
              if(ingredient.price.measureprice === v.choisMeasure) {
                const price = (ingredient.price.rate/ingredient.price.weightprice)*v.weight
                acc.price += Number(price)
              } else { 
                
                //перевести цену за основной вес
               // const rightPrice = ingredient.measure.find(i=> i.nameRu === ingredient.price.measureprice).ratio
                //const price = (ingredient.price.rate/(ingredient.price.weightprice*rightPrice)) * totalWeight
               // acc.price += Number(price)
              } 
            }
            return acc;
            }, {price: 0}); 
            price += result.price
  
 
        
      return {cal, price} 
    }


                              /* Действия с планом на день*/ 

  // Автозамена плана на день
  function handleAutoReplaceMenu() {  
    props.handleAutoReplaceMenu()  
  } 
  
  // Добавления дополнительного приема пищи в план
  function handleOpenAddMeals() {
    if(!blockedSaveMenu) {
      setIsOpen(!isOpen)
    } else {
      props.blockedSaveMenus('blockedSaveMenu')
    }  
  }

  //Добавление приема пищи в план
  function handleAddMeals(meals) { 
    props.handleAddMeals(meals, item) 
    setIsOpen(!isOpen)
  } 

                                 /* Действия с планом на каждый прием пищи*/ 
  //функция добавления блюда в план
  function handleAddMenuItem( item) { 
    props.handleAddMenuItem( item)
  }

  //функция удаления блюда из плана
  function handleDeleteRecipe(id, powerMode) {
    props.handleDeleteRecipe(id, powerMode, item)
  }

  // Автозамена блюда в плане, передает данные в модалку для замены рецепта
  function handleAutoReplace(recipe, mealsEn, mealsRu) { 
    props.handleAutoReplace(recipe, mealsEn, mealsRu, item)
  }
  
  //функция замены блюда из плана
  function handleEdite(id, mealsEn, mealsRu) {
    props.handleEdite(id, mealsEn, mealsRu, item)
  } 

 
  return ( 
    <div className="listOfProducts">
      <div className="listOfProducts-holidayMenu">
        <div className="holidayMenu-header">
          <h2>{item.title}</h2>
          <div className="holidai-button">
            <button className='gg-replaceDay x-size' onClick={handleAutoReplaceMenu}/> 
            <button className='gg-add x-size' onClick={handleOpenAddMeals}></button>  
            <Popup 
              isOpen={isOpen}
              ref={myRef}  
              type='meal'
            >
             {zeroMeals.map((item, index)=> (
               <AddMeals 
                item={item} 
                onClick={handleAddMeals} 
                key={item.mealsEn} />
              ))}
            </Popup> 
          </div>
        </div>  
      { newarr.map((i, index)=> (
          <MenuMeals 
            item={i} 
            key= {index}
            hoax={props.hoax}
            loader={loader}
            setHoax={props.setHoax}
            recipes={props.recipes}
            handleDeleteRecipe = {handleDeleteRecipe}
            handleEdite = {handleEdite}
            autoReplace={handleAutoReplace}
            handleAddDish={handleAddMenuItem}
            click={props.click}
            clickCreate={props.clickCreate}
            blockedSaveMenu={blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus} 
          />
        ))
 
      }
 
     <div className="holidayMenu-footer">
      {/*<div className="info-ccal">
        <img src={minCcal} alt="ccal" />
        <p>{Math.ceil(cal)} ккал</p>
       </div>*/}
      <div className="info-cost">
      <p> Бюджет меню: </p>
        <img src={minCost} alt="cost" /> 
        <p> {Math.ceil(price)} Р</p>
    </div>
      </div>
      </div>
      {props.product.length > 0 && 
      <section className='foodList'>
          <h3 className='foodlist__header font20Bold' >Список продуктов </h3> 
          <p className='foodlist__paragraph font16Norm'>Отметьте те продукты, которые у вас уже есть дома. Они не будут добавлены в список покупок.</p> 
          <div className= 'product-block ' >
              <div className='product__buy'>
                {lib.catIn && 
                lib.catIn.map(i=> (
                <ProductCat 
                  item={i} 
                  key={i._id}
                  checkFoodList={props.product}
                  handleChoice={props.handleChoice}
                  subDish={props.subDish}
                  servings={props.feastPlan.servings}
                />
                ))
                }
              </div>
              </div>
     </section> 
}
     </div>
  )  
})

function MenuMeals(props) {
  const {arr, mealsRu, mealsEn} = props.item
 
  return (
    <>
    {arr !==undefined && arr.length > 0 &&
    <article className="holidayMenu-main">
      <div className="holiday-menu-category">
        <div className="name-category">
          <h3>{mealsRu}</h3>
          <button onClick={()=> props.handleAddDish(props.item)} className='gg-add-dish m-size'/>
        </div>
 
        {arr.map((i, index)=> (
          <MenuDish 
            item={i} 
            click={props.click}
            loader={props.loader}
            mealsRu={mealsRu} 
            mealsEn={mealsEn}
            key={index}
            hoax={props.hoax} 
            recipes={props.recipes}
            setHoax={props.setHoax}
            handleDeleteRecipe={props.handleDeleteRecipe}
            handleEdite={props.handleEdite}
            autoReplace={props.autoReplace} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={props.blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus}
            countPlan={props.countPlan}
          />
        ))}
         </div>
    </article>
    }
    </>
  )  
}
 
export function MenuDish (props) {
  const {recipeDataMenu} = useSelector(state => state.recipes) 
  const {ingredients, status} = useSelector(state => state.ingredients) 
  let id= props.item  
  const [click, setClick] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
  let recipe = recipeDataMenu.find(i=> i._id === id) 
  let weight = (status === 'resolved' && recipe) && totals(recipe, ingredients) 
  const [isHovering, setIsHovering] = React.useState(false);  
 
  React.useEffect(()=> { 
    if(!props.click) { 
      setClick(false) 
    } 
  }, [props.click]) 

  let total = (status ==='resolved' && recipe) && recipe.ingredients.reduce(function (acc, item) { 
    const ingBase = ingredients.find(i=> i._id ===item._id) // находим по id нужный ингредиент
    if(ingBase.price) {
     
      if(item.choisMeasure === ingBase.mainMeasure && ingBase.price.measureprice === ingBase.mainMeasure) {
        const price = (ingBase.price.rate/ingBase.price.weightprice)*item.weight
        return acc + Number(price)
      } else { 
        let rightWeight = 1
        const searchWeight = ingBase.measure.find(i=> i.nameRu === item.choisMeasure) // перевели вес в основной
          if(searchWeight !==undefined) {
            rightWeight = searchWeight.ratio*item.weight
          }
        //перевести цену заосновной вес
        const rightPrice = ingBase.measure.find(i=> i.nameRu === ingBase.price.measureprice).ratio
     //   console.log(item.choisMeasure, item)
        const price = (ingBase.price.rate/(ingBase.price.weightprice*rightPrice)) * rightWeight
        return acc + Number(price)
      }
     
    } else {
       return 0
    }
  },0); 

  function handleReplace() {
    setClick(true)
    if(!props.blockedSaveMenu) {
      props.autoReplace(recipe, props.mealsEn, props.mealsRu)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 

  }

  function handleEdite() {
    setClick(true)
    props.handleEdite(id, props.mealsEn, props.mealsRu) 
  }

  function handleDeleteRecipe() {  
    props.handleDeleteRecipe(id, props.mealsEn) 
  }

  function handleClickAttention() {
    setIsHovering(!isHovering)
  } 
 
  return (
    <>
    {(props.loader || click) ?
      <div  className="info-items">
        <div className="info"> 
          <Loader />
        </div>  
      </div> 
      :
      <div  className="info-items">
        <div className="info"> 
          <img  className='info-img' src={recipe && recipe.image[0] }/> 
          <div className="info-main">
            <div className='name-ccal'> 
            <div className='feast-name'>
              <Link className='info-main-name font16Norm' target="_blank"  to={`/recipes/${id}`}>{recipe && recipe.name}</Link> 
              {recipe && recipe.preparation !=='' &&
              <button type='button' onClick={handleClickAttention} className='gg-bell tooltip m-size'>
                <span className="tooltiptext">{recipe.preparation} </span>
              </button>}
            </div>
              <div className="ccal"> 
                <div className="info-ccal">
                  <img src={kcal} alt="how" /> <p>{Math.round(recipe && weight/recipe.servings)}г</p>
                </div>  
                <div className="info-ccal">
                  <img src={time} alt="how" /> <p>{recipe && recipe.time}мин</p>
                </div>  
                <div className="info-ccal">
                  <img src={cost} alt="how" /> <p>{Math.round(recipe && total/recipe.servings)}р</p>
                </div>          
              </div>
            </div>
            <div className="info-button">
              <p className='gg-replace m-size' onClick={handleReplace}></p>
              <p className='gg-edit m-size' onClick={handleEdite}></p>
              <p className='gg-delete m-size' onClick={handleDeleteRecipe}></p>
            </div>
          </div> 
        </div> 
      </div> 
    }
    </>
  )
}

const Popup = React.forwardRef((props, ref) => (
  <div ref={ref} className={`feast__popup popup__type_meals ${props.isOpen ? 'popup__open' : ''}`} >
    {props.children} 
  </div>  
));
