// Разметка блюда в меню
import React from 'react';
import './MenuDish.css'
import { useSelector } from 'react-redux'
import { Loader } from '../../organisms/index.js' 
import { Link } from 'react-router-dom' 
import kcal from '../../../images/kcal.svg'
import time from '../../../images/times.svg'

export function MenuDish (props) {
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
  const {statusPlan} = useSelector(state => state.plans) 
  let id= props.item  
  let recipe = recipeDataMenu.find(i=> i._id === id)
  const [click, setClick] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
  const [edit, setEdit] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(()=> {
    if(statusPlan === 'resolved' ) {
      setClick(false)
      setEdit(false)
    }
    if(props.hoax ) {
      setClick(false)
      props.setHoax(false)
    }
    setIsHovering(false)
  }, [statusPlan, props.hoax]) 
  
  function handleReplace() {
    if(!props.blockedSaveMenu) {
      setClick(true)
      props.autoReplace(recipe, props.mealsEn, props.mealsRu)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }

  function handleEdite() {
    if(!props.blockedSaveMenu) {
      setClick(true)
      setEdit(true)
      props.handleEdite(id, props.mealsEn, props.mealsRu)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }

  function handleDeleteRecipe() { 
    if(!props.blockedSaveMenu) {
      setClick(true)
      props.handleDeleteRecipe(id, props.mealsEn, props.index)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }

  function handleClickAttention() {
    setIsHovering(!isHovering)
  }
 
  return (
    <>
    {( props.click || props.clickCreate) && (statusPlan === 'loading' || statusMenu ===  'loading') 
      ||(recipe === undefined) || edit || click ?
    <div className='menu-dish'> 
      <div className="info"> 
          <Loader />
        </div>  
    </div>
    :
    <div className='menu-dish'>
      <img  className='menu-dish__image' src={recipe && recipe.image[0] }/> 
      <div className="info-main">
      <div className='name-ccal'> 
      <div className={`menu-dish__heading ${props.index> 0 && 'menu-dish__heading_margin'}`}>
        <Link className='menu-dish__title font16Norm' target="_blank"  to={`/recipes/${id}`}>{recipe && recipe.name}</Link>
        {recipe && recipe.preparation !=='' &&
              <button type='button' onClick={handleClickAttention} className='gg-bell tooltip m-size'>
                <span className="tooltiptext">{recipe.preparation} </span>
              </button>}
        </div>
        <div className="ccal"> 
                <div className="info-ccal">
                  <img src={kcal} alt="how" /> <p>{Math.round(recipe && recipe.calories.calories/recipe.servings)}ккал</p>
                </div>  
                <div className="info-ccal">
                  <img src={time} alt="how" /> <p>{recipe && recipe.time}мин</p>
                </div>  
                    
              </div>
      </div>
      <div className="info-button">
              <p className='gg-replace m-size' onClick={handleReplace}></p>
              <p className='gg-edit m-size' onClick={handleEdite}></p>
              <p className='gg-delete m-size' onClick={handleDeleteRecipe}></p>
            </div>
    </div>
    </div>
    }
  </>
  )
}