import React from 'react';
import './InfoPopup.css'; 
 
export const InfoPopup = React.forwardRef((props, ref) => ( 
  <div ref={ref} className={`feast__popup popup__type_meals popup__${props.type} ${props.isOpen ? 'popup__open' : ''}`} >
    
      {props.children}
    
     
  </div>  
));