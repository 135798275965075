import {mealsArray, weekdayArray} from '../constants/constants.js'
  export const BASE_URL = 'https://api.menubook.ru';
  //   export const BASE_URL = 'http://localhost:3002';
     
const getResponsData = (res)=> {
  if(res.ok) {
    return res.json()
  
   }else {
     return Promise.reject(res.status)
   } 
}

export const register = (email, password, name, subscription, lib) => { 
  return fetch(`${BASE_URL}/signup`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email, 
      password, 
      name,
      bonus: {
        summ: 0,
        recipeActive: [],
        sumTraffick: [],
        recipeInActive: [],
      },
      option: [
        {
        sumFamily: 1,
        typeOfDiet: lib.typeFoods,
        kitchenware: lib.kitchenware,
        powerMode: mealsArray,
        period: weekdayArray,
        periodAn: 'everyday',
        stopProducts: [],
        maxCals: 2000,
        cookingTime: 60,
        title: 'Menu2',
        active: false,
        fresh: false,
      },
      {
        sumFamily: 1,
        typeOfDiet: lib.typeFoods,
        kitchenware: lib.kitchenware,
        powerMode: mealsArray,
        period: weekdayArray,
        periodAn: 'everyday',
        stopProducts: [],
        cookingTime: 60,
        maxCals: 2000,
        title: 'Menu3',
        active: false,
        fresh: false,
      }
    ],
      settings: {
        sumFamily: 1,
        typeOfDiet: lib.typeFoods,
        powerMode: mealsArray,
        period: weekdayArray,
        periodAn: 'everyday',
        stopProducts: [],
        kitchenware: lib.kitchenware,
        maxCals: 2000,
        fresh: false,
        cookingTime: 60,
        title: 'Menu1',
        active: true,
      },
      acces: true,
      subscriptions: subscription,
      dateAcces: new Date().getTime() + 5356800000,
      finance: {
        summ: 0,
        receiptTraffic: [],
        writeDownsTraffic: [],
      }
    })
  })
  .then(getResponsData)
};

export const editContent = (email, name, phone, login, acces) => {
    return fetch(`${BASE_URL}/users/me`, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({email, name, phone, login, acces})
    })
    .then(getResponsData);
  }

  export const editPhoto = (photo) => {
    return fetch(`${BASE_URL}/users/me/photo`, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify( {photo})
    })
    .then(getResponsData);
  }


export const authorize = (email, password) => {
  return fetch(`${BASE_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({email, password})
  })
  .then(getResponsData);
}; 

export const reset = (email) => {
  return fetch(`${BASE_URL}/reset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({email})
  })
  .then(getResponsData);
}; 

export const updatePassword = (password, userId, token) => {
  return fetch(`${BASE_URL}/password/${userId}/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({password})
  })
  .then(getResponsData);
}; 

export const getContent = () => {
  return fetch(`${BASE_URL}/users/me`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
  })
  .then(getResponsData);
} 

export const signOut = () => {
  return fetch(`${BASE_URL}/signout`, {
    method: "POST",
    credentials: "include",
  })
  .then(getResponsData);
}
