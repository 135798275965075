/* Вывод списка продуктов из созданных менюшек */

import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {DishChecking} from '../MenuCreate/Checking.js'
import { resulted } from '../../../utils/utils.js'
import { getFoodList, updateFoodList, postFoodList, deleteFoodList } from '../../../store/api.js'
import { ProductCat, PrintThisComponent} from '../../atoms/index.js' 
import { SceletonProduct, PriceCounter } from "../index.js";
import './ProductList.css'; 
import {declensions} from '../../../constants/constants'
import PdfDownload  from './MyComponent.js';
 
export function ProductList (props) {
  const {date, menuPlane, changeMenuDay } = props 
  const { currentUser } = useSelector(state => state.currentUser)
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
  const { productList, statusProductList } = useSelector(state => state.productList) // База сохраненных продуктовых списков
  const dispatch = useDispatch();
  const { ingredients, status } = useSelector(state => state.ingredients)
  let startDay = date.length>0 ? new Date(date[0].date).toLocaleDateString() : ''
  let finishDay = date.length>0 ?  new Date(date[date.length-1].date).toLocaleDateString() : ''; 
  const [subDish, setSubDish] = React.useState([]) // Список блюд где исп-ся продукты
  const [print, setPrint] = React.useState(false) 
  const { lib } = useSelector(state => state.lib)
 //const check = Object.entries(baseProducts).length !== 0 && baseProducts.productList.some(f=> f.id === choiceIngredient._id);
  const [productFood, setProductFood] = React.useState([])
  const  servings =  currentUser.settings.sumFamily
  const [result, setResult] = React.useState({}) 
 
  // Запрос списка продуктов при изменении даты
   React.useEffect(() => {
    if(Object.keys(currentUser).length !== 0) {
      dispatch(getFoodList({date: date, id: currentUser._id}))
    }
  },[currentUser, date])  
 
  const findDeclension = (number, word) => {
    const declension = declensions.find(item => item.ncase === word);
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return declension.ncase;
    }
    n %= 10; 
    if (n >= 1 && n <= 4) {
      return declension.gcase;
    }
    return declension.gcasemulti; 
    }

   // Запрос списка продуктов при изменении даты
   React.useEffect(() => { 
    if(Object.keys(productList).length > 0 && changeMenuDay) { 
      dispatch(deleteFoodList(productList._id))
    } else if(Object.keys(productList).length > 0 && !changeMenuDay) {
      setProductFood(productList.productList)
    } else {
       setProductFood(resulted(menuPlane, servings))
    }
  },[productList, changeMenuDay, menuPlane, servings])  

  //Редактирование ингредиентов из общего списка продуктов
  function handleSaveEditProducts(item) {
    props.handleChangeMenu()
    const editFoodlist = productFood.map(k=> k.id === item.id ? item : k)
    if (Object.keys(productList).length === 0) {
      dispatch(postFoodList({editFoodlist, date}))
    } else { 
      dispatch(updateFoodList({cardId: productList._id, editFoodlist}))
    } 
  } 
  React.useEffect(() => { 
    if(Object.keys(productList).length > 0) {  
      const editFoodlist = resulted(menuPlane, currentUser.settings.sumFamily)
      editFoodlist.map(i=> {
        let arr = productList.productList.find(k => k.id === i.id); 
          if (arr) {
            let updatedObj = {
              id: i.id,
              choice: arr.choice ,
              choisMeasure: i.choisMeasure,
              excess: arr.excess, 
              weight: i.weight,
            }; 
            editFoodlist[editFoodlist.indexOf(i)] = updatedObj
          }
        })  
        dispatch(updateFoodList({cardId: productList._id, editFoodlist}))
    }
  },[servings])

  //При изменении меню и пользователя пересчитывает в каких рецептах используются продукты
  React.useEffect(() => { 
    if(status === 'resolved') { 
      setSubDish(DishChecking(menuPlane, recipeDataMenu, ingredients))
    }
  },[menuPlane]) 

                    /* Расчет стоимости продуктов*/
  React.useEffect(() => { 
    if(productFood.length > 0 && status === 'resolved') { 
      const newarr = productFood.map((item)=> {
        const product =  ingredients.find(i => i._id === item.id) 
        if (product) {
          return {...product.price, ...item, mainMeasure: product.mainMeasure, measure: product.measure}
        }
      })  
      let result = newarr.reduce(function(acc,v, index){
        if(!v.choice) {
          if(v.choisMeasure === v.measureprice) {
            acc.price += (v.rate/v.weightprice)*v.weight
          } else if(v.choisMeasure !== v.measureprice) {
            const totalWeights = v.measure.find(i=> i.nameRu === v.choisMeasure)
              if(totalWeights !== undefined && v.measureprice !== undefined) { 
                const totalWeight = totalWeights.ratio*v.weight;
                acc.price += (v.rate/v.weightprice)*totalWeight
              }
            }
        }
        return acc
      }, {price : 0});
      setResult(result)
    }
    
  },[productFood, status])
 
  function handlePrint() {
    setPrint(!print)
  } 
  
                   
 
  

  return (
    <section className='foodList'>
      <h3 className='foodlist__header font35Bold' >Список продуктов </h3>
      {/*<PrintThisComponent onClick={ props.handlePrint}/>*/}
      <p className='foodlist__subtitle font24Bold'>Список составлен автоматически, его можно редактировать</p>
      <p className='foodlist__paragraph font24Norm'>Отметьте те продукты, которые у вас уже есть дома. Они не будут добавлены в список покупок.</p>
      <p className='foodlist__text font16Norm'>Продукты указаны на {servings} {findDeclension(servings, 'человек')}, {startDay === finishDay ? `на 1 день: ${startDay}` : `c ${startDay} по ${finishDay}`}</p>
      { productFood.length > 0 && statusMenu === 'resolved' ?
        <div className={`product-block ${statusProductList === 'loading' ? 'col__search' : ''}`}>
          <div className='product__buy'>
            {lib.catIn && 
            lib.catIn.map(i=> (
            <ProductCat 
              item={i} 
              key={i._id}
              checkFoodList={productFood}
              handleChoice={handleSaveEditProducts}
              subDish={subDish}
              servings={servings}
            />
            ))
            }
          </div>
          <p className='product__price'>Продукты на {servings} {findDeclension(servings, 'человек')} &#8776; {Math.ceil(result.price)} руб.</p>
          <button className='button__pdf'> <PdfDownload  
            catIn={lib.catIn}  
            checkFoodList={productFood}
            finishDay={finishDay}
            startDay={startDay}
            servings={servings}
          />
        </button> 
      </div>
      :  <SceletonProduct /> 
      } 
    </section>
  )
}