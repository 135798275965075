//Страница со списком рецептов

import React from 'react'; 
import {useSelector, useDispatch } from 'react-redux'
import { Recipe, SceletonRecipe, SideFilter, SceletonSideFilter } from '../../organisms/index.js'
import { Search, SearchByIngredient } from '../../molecules/index.js' 
import { Radio } from '../../atoms/index.js'
import { filtrSearchArr } from '../../../utils/utils.js'
import { Button } from 'antd';
import { fetchFilter } from '../../../store/api.js' 

export function ChoiceInRecipe(props) {  
  const dispatch = useDispatch();
  const {lib, statusLib} = useSelector(state => state.lib)
  const { currentUser } = useSelector(state => state.currentUser)
  const {recipePage, ownerRecipe, recipeLike, statusLike, sts, totalCount, fetching} = useSelector(state => state.recipes) 
  const {ingredients, status} = useSelector(state => state.ingredients) 
  const [showFilterBlock, setShowFilterBlock] = React.useState(false)
  const [category, setCategory] = React.useState([])
  const [type, setType] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1) 
  const [choiceIngredient, setChoiceIngredient] = React.useState([]) 
  const limit = 10
 let filters = JSON.parse(localStorage.getItem('filters'));
  const allPages = Math.ceil(totalCount/limit)
  const pages = Array.from({length: allPages}, (_, i) => i + 1) 
  const [keyword, setKeyword] = React.useState('')    
  const [keywords, setKeywords] = React.useState('')
  const [hidden, sethidden] = React.useState(true)          
  const [filtrMassiv, setFiltrMassiv] = React.useState([]);
  const [inputTimer, setInputTimer] = React.useState(null); 
  //const [likeOpen, setLikeOpen] = React.useState(1)  
  const [statusRec, setStatusRec] = React.useState('all')   
  const submitDisabled = filterSearch() || keyword || parametrkeywords()  

  let parametrSearch = {
    currentPage: currentPage,
    params : filterSearch(),
    searchWords: keyword,
    ingredient : parametrkeywords(),
    limit: limit,  
    like: statusRec === 'like' ? currentUser._id : '',
    owner: statusRec === 'owner' ? currentUser._id : '',
    feast: statusRec === 'feast' ? true : '',
  } 

                              ////* Постраничная пагинация *//////

  React.useEffect(()=> { 
    if(filters !== null) {
      setType(filters.type)
      setCategory(filters.category)
    }
  },[]) 
  
  // Запрос данных из библиотеки
  React.useMemo(()=> { 
  if(!filters && statusLib === 'resolved') {
    setCategory(lib.dish)
    setType(lib.typeFoods) 
  }
  }, [lib])
  
  /// Проверка условий для запроса на сервер                
  React.useEffect(()=> { 
    if(currentPage && category.length> 0 && type.length> 0) {
      dispatch(fetchFilter(parametrSearch))
    } 
  },[currentPage,category, type, choiceIngredient, statusRec])

  React.useEffect(()=> { 
    if(currentPage && category.length> 0 && type.length> 0) {
      clearTimeout(inputTimer);
    let timeout = setTimeout(() => {
      parametrSearch.searchWords = keyword 
      dispatch(fetchFilter(parametrSearch))
    }, 1000);
    setInputTimer(timeout);
     
    } 
  },[keyword])

  // Сброс счетчика страниц на 1 при фильтрации               
  React.useEffect(()=> { 
    setCurrentPage(1)
    if(type.length > 0 || category.length > 0) { 
      localStorage.setItem(
        "filters",
        JSON.stringify({type : type, category : category})
      );
    }
  },[category, type])
  
  React.useEffect(() => {
    if(showFilterBlock) {
      document.body.classList.add('hidden'); 
    } else {
      document.body.classList.remove('hidden');
    } 
  }, [showFilterBlock]);

 //Счетчик изменения страниц
 function handleCounter(page) {
  setCurrentPage(page)
}

  //Создание поисковой строки для фильтров
  function filterSearch() {
    let params='' 
    if(type.length > 0 && category.length > 0) {
      type.map(s => {
        if(s.boolean) {
          params = params + `&types[]=${s.nameEn}`

        }
      }) 
      category.map(s => {
        if(s.boolean) {
          params = params + `&dishes[]=${s.nameRu}`
        }
      })
    } 
    return params
  }

  //Функция поиска по названию рецепта
  function handleSearchDish(e) {
    setKeyword(e.target.value) 
  } 

  function parametrkeywords() {
    let param=''
    choiceIngredient.map(i=> {
      param = param + `&keywords[]=${i._id}`
    })
    return param
  }

  function handleChoiceCategory(item) { 
    setCategory(() => category.map((c) => c.nameEn === item.nameEn ? item : c))
  }

  function handleChoiceType(item) { 
    setType(() => type.map((c) => c.nameEn === item.nameEn ? item : c)) 
  }
 
  function handleFilterBlock() {
    setShowFilterBlock(!showFilterBlock)
  }
 
  function handleDelete(i) {
    setChoiceIngredient((item)=> item.filter((c) => c._id !== i)) 
  }

  function handleAutoText(ing) {
    setChoiceIngredient([...choiceIngredient, {_id: ing._id, name: ing.name}])
    setKeywords('')
    sethidden(false)
  }
  function handleChangeInput(e) {
    handleFiltrClick(e.target.value)
    setKeywords(e.target.value);
    //setKeyword('')
    //errorMessage(e)
    sethidden(true)
  } 

  function handleReset(e) {  
    setType(lib.typeFoods)
    setCategory(lib.dish)
    setChoiceIngredient([])
    setKeyword('')
    localStorage.removeItem('filters')
  } 

  function handleFiltrClick(keyword) {
    if(keyword!=='') {
      const newMassiv = filtrSearchArr(ingredients, keyword, 'ingredient')
      setFiltrMassiv(newMassiv)
      } else {
        setFiltrMassiv([])
      }
  }  

  function handleChangeRecipe(recipe) {
    setStatusRec(recipe)
  }

  const handleCheckBoxPeriod = (e) => {
    setStatusRec(e.target.value) 
  };
 
  return (
    <div> 
      <div className='recipes'> 
        <div className='recipes__head'> 
          <h1 className='recipe__title font35Bold'>Рецепты</h1>  
          <div className='filters__button color menuweek-button' onClick={handleFilterBlock}>
            <span className=' gg-filter l-size'></span>
            <span>Фильтры</span>
          </div>   
        </div>
      <div className='row'>
      {status=== 'resolved' ?
        <SideFilter 
          type={type}
          category={category}
          fetching={fetching}
          handleChoiceCategory={handleChoiceCategory}
          handleChoiceType={handleChoiceType}
          scrollTop={props.scrollTop}
          showFilterBlock={showFilterBlock}
          handleFilterBlock={handleFilterBlock}
        />
        : <SceletonSideFilter />
      }
         <section  className={`col col__content ${fetching=== 'loading' ? 'col__search' : props.scrollTop > 0 ? 'col__article_sticky': ''}`}>
          <form className='recipes__search' >
            <Search onSearch={handleSearchDish} keyword={keyword} text='по рецептам' placeholder='Искать по названию'/>
            <SearchByIngredient 
              handleAutoText={handleAutoText} 
              handleDelete={handleDelete} 
              hidden={hidden}
              keywords={keywords}
              filtrMassiv={filtrMassiv}
              handleChangeInput={handleChangeInput}
              choiceIngredient={choiceIngredient}
              text='по ингредиентам' 
              placeholder='Искать по ингредиентам'
            />
            <Button className={`menuweek-button ${submitDisabled ? 'color' : ''}`} onClick={handleReset}>Сбросить фильтры</Button>
            <Radio statusRec={statusRec} onChange={handleCheckBoxPeriod}  feast={props.feast} />
          </form>
          {recipePage.length> 0  ?
          <div className='col col__article'>
            {recipePage.map((item)=> (
              <Recipe 
                type='inMenu'
                onCardLike={props.onCardLike}  
                loggedIn={props.loggedIn}
                recipe={item} 
                key={item._id}
                addRecipe={props.addRecipe} 
                replaceRecipe={props.replaceRecipe} 
                replace={props.replace} 
                closeModal={props.closeModal} 
                positionMenuItem={props.positionMenuItem}
              />
            )) 
            }
             </div>
              : fetching === 'resolved' && recipePage.length === 0 ?
              <p className='recipes__error'>Рецепты соответствующие запросу не найдены</p>
              :
              <div className='col col__article'>
              <SceletonRecipe />
              </div>
            }
             <div className='pages'>
                {pages.map((page, index)=> 
                  <span 
                  className={`page__number ${currentPage === page ? 'page__current' : ''}`}
                  key={index} 
                  onClick={()=>handleCounter(page)}>{page}</span>
               )}
             </div>
        </section>
       
      </div>
    </div>
   
    </div>
  )  
}
 